/* eslint-disable no-undef */
import apis from "./../apis/index";
import wx from 'weixin-js-sdk';
const { getJssdkSignRequest } = apis;
// 微信配置
export const wechatJssdkConfig = (jsApiList) => {
    const data = {
        pageUrl: location.href
    };
    getJssdkSignRequest({ params: data }).then(res => {
        const config = res.data;
        wx.config({
            debug: false,
            appId: config.appId,
            timestamp: config.timestamp,
            nonceStr: config.noncestr,
            signature: config.signature,
            jsApiList
        });
    });
};
// 微信分享
export const weChatShare = (params, callBack) => {
    const data = {
        pageUrl: location.href
    };
    getJssdkSignRequest({ params: data }).then(res => {
        const config = res.data;
        wx.config({
            debug: false,
            appId: config.appId,
            timestamp: config.timestamp,
            nonceStr: config.nonceStr,
            signature: config.signature,
            jsApiList: ["onMenuShareAppMessage",
                "onMenuShareTimeline"]
        });
        wx.ready(function () {
            const shareCon = {
                title: params.title,
                desc: params.desc,
                link: params.link,
                imgUrl: params.imgUrl,
                type: params.type || "link"
            };
            // 分享给朋友
            wx.onMenuShareAppMessage({
                ...shareCon,
                success: function () {
                    // 用户确认分享后执行的回调函数
                    callBack && callBack();
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });

            wx.onMenuShareTimeline({
                ...shareCon,
                success: function () {
                    // 用户确认分享后执行的回调函数
                    callBack && callBack();
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });
        });
    });
};


// 获取code
// export const getWechatCode = ( appid, url ) => {
//     window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + encodeURIComponent( url ) + "&response_type=code&scope=snsapi_base&state=1#wechat_redirect";
// };

